<!--
 * @Description: 请输入文件描述
 * @Date: 2020-01-10 14:42:20
 * @LastEditTime: 2020-08-24 14:11:32
 * @LastEditors: 胡椒
 -->
<template>
  <div id='withdraw-apply'>
    <a-tabs type='card' v-model="currentTab" @change='tabChangeCallback'>
      <a-tab-pane tab='申请列表' key='1'></a-tab-pane>
      <a-tab-pane tab='审核记录' key='2'></a-tab-pane>
    </a-tabs>
    <div class="tab-content">
      <a-form class='filter-condition-form' layout='inline' :form='form' @submit='handleFormSubmit' v-auth="'cash_manage_apply_list'">
        <a-form-item>
          <a-input v-decorator="['search']" placeholder='注册手机/姓名/处理人' allowClear @change="handleSearchInputChange" />
        </a-form-item>
        <a-form-item label="处理状态" v-if="currentTab === '2'">
          <a-select v-decorator="['status', { initialValue: 'all' }]" defaultValue="all" style="width: 120px">
            <a-select-option value="all">全部</a-select-option>
            <a-select-option :value="WITHDRAW_STATUS.PAID.VALUE">提现成功</a-select-option>
            <a-select-option :value="WITHDRAW_STATUS.REJECT.VALUE">驳回申请</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type='primary' html-type='submit'>查询</a-button>
        </a-form-item>
      </a-form>
      <a-table
        :scroll="{ y: tableScrollY }"
        v-auth="'cash_manage_apply_list'"
        :rowKey='record => record.id'
        :columns='columns'
        :dataSource='list'
        :pagination='pagination'
        :locale='{ emptyText: "暂无提现审核记录" }'
        :loading='loading'
        :customRow="handleRecordClick"
        @change='handleTableChange'
      >
        <template slot='operation' slot-scope='record'>
          <a-button type='primary' size='small' @click='handleAudioClick(record)' v-auth="'cash_manage_modify_apply'">处理</a-button>
        </template>
        <template slot='status' slot-scope='status'>
          <span :class="{ 'passed': WITHDRAW_STATUS.PAID.VALUE == status, 'not-pass': WITHDRAW_STATUS.REJECT.VALUE == status }">{{getStatusText(status)}}</span>
        </template>
      </a-table>
    </div>
    <a-drawer
      title='提现审核'
      placement='right'
      width='850'
      :visible='withdrawDrawerVisiable'
      @close='handleWithdrawDrawerClose'
    >
      <WithdrawApprovalDetail ref='withdrawApproval' :detail='drawerDetail' @close='handleWithdrawDrawerClose' />
    </a-drawer>
  </div>
</template>

<script>
import { getWithdrawList } from '../../../service/user'
import { WITHDRAW_STATUS } from '../../../const/index'
import WithdrawApprovalDetail from '../../../components/admin/withdraw-approval-detail'
import { checkPer } from "@/utils/index";

const listColumns = [
  {
    title: '提现流水号',
    dataIndex: 'number',
    align: 'center',
    width: 200,
  },
  {
    title: '注册手机',
    dataIndex: 'user__mobile',
    align: 'center',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '提现金额',
    dataIndex: 'money',
    align: 'center',
  },
  {
    title: '申请时间',
    dataIndex: 'create_time',
    align: 'center',
  },
  {
    title: '操作',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
    align: 'center',
  }
]

const recordColumns = [
  {
    title: '提现流水号',
    dataIndex: 'number',
    align: 'center',
    width: 200,
  },
  {
    title: '注册手机',
    dataIndex: 'user__mobile',
    align: 'center',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '提现金额',
    dataIndex: 'money',
    align: 'center',
  },
  {
    title: '处理状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
  },
  {
    title: '处理人',
    dataIndex: 'audit_user__username',
    align: 'center',
  },
  {
    title: '处理时间',
    dataIndex: 'audit_time',
    align: 'center',
  }
]

export default {
  name: 'withdraw-apply',
  components: { WithdrawApprovalDetail },
  data () {
    return {
      tableScrollY: 600,
      WITHDRAW_STATUS,
      currentTab: '1',
      form: this.$form.createForm(this),
      list: [],
      columns: listColumns,
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: total => `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(total / this.pagination.pageSize)} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => this.pagination.pageSize = pageSize
      },
      withdrawDrawerVisiable: false,
      drawerDetail: null,
      loading: false
    }
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 345;
  },
  mounted () {
    // 跳转过来带参数
    if (this.$route.query.currentTab) this.currentTab = this.$route.query.currentTab
    // 跳转过来带关键词的
    if (this.$route.query.search) this.form.setFieldsValue({ search: this.$route.query.search })
    // 查询数据
    // 初始第一次获取
    if (this.$route.query.currentTab === '2') {
      this.columns = recordColumns
      this.getWithdrawList('record')
    } else {
      this.columns = listColumns
      this.getWithdrawList()
    }
  },
  methods: {
    // 切换tab
    tabChangeCallback (key) {
      this.currentTab = key
      this.form.resetFields()
      if (key === '1') {
        this.columns = listColumns
        this.pagination.current = 1
        this.getWithdrawList()
      } else if (key === '2') {
        this.columns = recordColumns
        this.pagination.current = 1
        this.getWithdrawList('record')
      }
    },
    // 搜索表单提交
    handleFormSubmit (e) {
      e.preventDefault()
      this.pagination.current = 1
      if (this.currentTab === '1') this.getWithdrawList()
      if (this.currentTab === '2') this.getWithdrawList('record')
    },
    // 审核
    handleAudioClick (record) {
      this.drawerDetail = record
      this.withdrawDrawerVisiable = true
      this.$nextTick(() => {
        this.$refs.withdrawApproval.init()
      })
    },
    // 表格分页
    handleTableChange (pagination) {
      if (pagination) this.pagination.current = pagination.current
      if (this.currentTab === '1') {
        this.getWithdrawList()
      } else if (this.currentTab === '2') {
        this.getWithdrawList('record')
      }
    },
    // 获取提现列表
    async getWithdrawList (type = 'list') {
      if (!checkPer("cash_manage_apply_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      this.loading = true
      const data = this.form.getFieldsValue()
      if (data.status === 'all') data.status = undefined
      data.page = this.pagination.current
      data.page_size = this.pagination.pageSize

      if (type === 'list') data.status = WITHDRAW_STATUS.PROCESSING.VALUE
      if (type === 'record') data.status__gte = 2
      // 审核记录按审核时间排序
      if (this.currentTab === '2') data.ordering = '-audit_time'
      const { err, res } = await getWithdrawList(data)
      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination }
          pagination.total = res.data.count
          this.pagination = pagination
          this.list = res.data.results
        } else {
          this.$message.error(res.message)
        }
      }
      this.loading = false
    },
    // 获取状态名称
    getStatusText (status) {
      let statusText = ''
      for (let key in WITHDRAW_STATUS) {
        if (status === WITHDRAW_STATUS[key].VALUE) {
          statusText = WITHDRAW_STATUS[key].TEXT
        }
      }
      return statusText
    },
    // 输入框文字清空
    handleSearchInputChange (e) {
      if (e.target.value !== '') return false
      this.pagination.current = 1
      this.$nextTick(() => {
        if (this.currentTab === '1') {
          this.getWithdrawList()
        } else if (this.currentTab === '2') {
          this.getWithdrawList('record')
        }
      })
    },
    // 详情弹窗关闭回调事件
    handleWithdrawDrawerClose () {
      this.withdrawDrawerVisiable = false
      if (this.currentTab === '1') this.getWithdrawList()
      if (this.currentTab === '2') this.getWithdrawList('record')
    },
    handleRecordClick (record) {
      if (this.currentTab === '1') return false
      return {
        on: {
          click: () => {
            this.drawerDetail = record
            this.withdrawDrawerVisiable = true
            this.$nextTick(() => {
              this.$refs.withdrawApproval.init('show')
            })
          }
        }
      }
    }
  }
}
</script>

<style lang='less' scoped>
@import '../../../assets/less/var.less';
#withdraw-apply {
  .passed {
    color: @green-color;
  }
  .not-pass {
    color: @red-color;
  }
}
</style>
