<!--
 * @Description: 提现审批详情
 * @Date: 2020-01-15 15:20:09
 * @LastEditTime: 2020-02-19 20:59:00
 * @LastEditors: 白青
 -->
<template>
  <div id='withdraw-approval-detail'>
    <a-card title="提现信息">
      <p class="withdraw-info">提现金额：{{detailInfo.money}}</p>
      <p class="withdraw-info">银行卡号：{{detailInfo.bank_card_num}}</p>
      <p class="withdraw-info">开户行：{{detailInfo.opening_bank}}</p>
      <p class="withdraw-info">所属银行：{{detailInfo.bank}}</p>
      <p class="withdraw-info">真实姓名：{{detailInfo.name}}</p>
      <p class="withdraw-info">身份证号：{{detailInfo.identity}}</p>
      <p class="withdraw-info">提现流水号：{{detailInfo.number}}</p>
      <p class="withdraw-info">申请时间：{{detailInfo.create_time}}</p>
      <a v-if="isApproval" class="look-over-withdraw-record" target="_blank" :href="`/admin/withdraw/withdraw?currentTab=2&search=${detailInfo.user__mobile}`">查看提现记录</a>
    </a-card>
    <br />
    <a-card title="提现处理">
      <a-form v-if="isApproval" :form="approvalForm" layout="horizontal" @submit="handleApprovalSubmit">
        <a-form-item label="处理结果" :label-col="{ span: 5 }" :wrapper-col="{ span: 10 }">
          <a-select v-decorator="[ 'status', { rules: [{ required: true, message: '请选择审核结果' }, { validator: processStatusValidator }], trigger: 'change' } ]" placeholder="请选择" @change="handleApprovalStatusChange">
            <a-select-option value='请选择'>请选择</a-select-option>
            <a-select-option :value="WITHDRAW_STATUS.PAID.VALUE">{{WITHDRAW_STATUS.PAID.TEXT}}</a-select-option>
            <a-select-option :value="WITHDRAW_STATUS.REJECT.VALUE">{{WITHDRAW_STATUS.REJECT.TEXT}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="isPaid" label="银行业务编号" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
          <a-row :gutter="8">
            <a-col :span="13">
              <a-input v-decorator="[ 'bank_number', { rules: [{ required: false, message: '请输入银行业务编号' }] } ]" placeholder="请输入银行业务编号" />
            </a-col>
            <a-col v-if="oss" :span="11">
              <a-upload name="file" :action="oss.host" :showUploadList="false" :customRequest="handleFileUpload">
                <a-button class="upload-pic-btn" type="link">上传业务截图</a-button>
              </a-upload>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item v-if="isPaid || isReject" :label="remarkName" :label-col="{ span: 5 }" :wrapper-col="{ span: 10 }">
          <a-textarea v-decorator="[ 'remark', { rules: [{ required: isReject, message: '请输入' + remarkName() }] } ]" :placeholder="'请输入' + remarkName()"></a-textarea>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 5 }">
          <a-button @click="handleApprovalCancel">取消</a-button>
          <a-button class="approval-submit-btn" type="primary" html-type="submit">提交</a-button>
        </a-form-item>
      </a-form>
      <div v-else>
        <p>处理结果：{{getStatusText(detailInfo.status)}}</p>
        <p>银行业务编号：{{detailInfo.bank_number}}</p>
        <p>业务截图：{{detailInfo.remark_pic || '无'}}</p>
        <p>{{remarkName()}}：{{detailInfo.remark || '无'}}</p>
        <p>处理人：{{detailInfo.user}}</p>
        <p>处理时间：{{detailInfo.audit_time}}</p>
      </div>
    </a-card>
  </div>
</template>

<script>
import { WITHDRAW_STATUS } from '../../const/index'
import { generateUUID } from '../../utils/index'
import { getWithdrawRecordDetail, approvalWithdrawApply, getOssToken, uploadOss } from '../../service/user'
export default {
  name: 'WithdrawApprovalDetail',
  props: [ 'detail' ],
  data () {
    return {
      WITHDRAW_STATUS,
      detailInfo: {},
      approvalForm: this.$form.createForm(this),
      isApproval: true,
      // 审批状态的值
      status: null,
      // 银行凭证图片地址
      remark_pic: '',
      oss: null
    }
  },
  computed: {
    // 是否选择了驳回
    isReject: function () {
      return this.status === WITHDRAW_STATUS.REJECT.VALUE
    },
    isPaid: function () {
      return this.status === WITHDRAW_STATUS.PAID.VALUE
    },
    remarkName: function () {
      return () => {
        if (this.isApproval) {
          if (this.status === WITHDRAW_STATUS.PAID.VALUE) return '备注'
          if (this.status === WITHDRAW_STATUS.REJECT.VALUE) return '驳回理由'
        } else {
          if (this.detailInfo.status === WITHDRAW_STATUS.PAID.VALUE) return '备注'
          if (this.detailInfo.status === WITHDRAW_STATUS.REJECT.VALUE) return '驳回理由'
        }
      }
    }
  },
  methods: {
    // 初始化
    init (type) {
      this.isApproval = type !== 'show'
      // this.detailInfo = this.$props.detail
      this.status = null
      this.approvalForm.resetFields()
      this.getWithdrawRecordDetail()
      this.getOssToken()
    },
    // 提交审批表单
    handleApprovalSubmit (e) {
      e.preventDefault()
      this.approvalForm.validateFields(async (err, values) => {
        if (!err) {
          const data = values
          if (this.remark_pic) data.remark_pic = this.remark_pic
          const { err, res } = await approvalWithdrawApply(this.detailInfo.id, data)
          if (!err) {
            if (res.success) {
              this.$message.success('提现审批操作成功')
              this.$emit('close')
            } else {
              this.$message.error(res.message)
            }
          }
        }
      })
    },
    // 审批表单取消
    handleApprovalCancel () {
      this.approvalForm.resetFields()
      // 使用this.$data修改status的值时为了触发computed方法
      this.$data.status = null
    },
    // 审核状态发生变化
    handleApprovalStatusChange (value) {
      this.status = value
      // debugger
    },
    // 获取提现记录详情
    async getWithdrawRecordDetail () {
      if (!this.$props.detail) return false
      const { err, res } = await getWithdrawRecordDetail(this.$props.detail.id)
      if (!err) {
        if (res.success) {
          this.detailInfo = res.data
        } else {
          this.$message.error(res.message)
        }
      }
    },
    // 获取osstoken
    async getOssToken () {
      const { err, res } = await getOssToken()
      if (!err && res.success) this.oss = res.data
    },
    // 文件上传方法
    async handleFileUpload (file) {
      if (!this.oss) await this.getOssToken()
      if (!this.oss) {
        this.$message.warn('上传失败')
        return false
      }
      const formData = new FormData()
      const pathArr = file.file.name.split('.')
      const key = `${this.oss.dir}withdraw/${generateUUID()}.${pathArr[pathArr.length - 1]}`
      formData.append('key', key)
      formData.append('policy', this.oss.policy)
      formData.append('signature', this.oss.signature)
      formData.append('success_action_status', '200')
      formData.append('OSSAccessKeyId', this.oss.accessid)
      formData.append('file', file.file)
      
      const res = await uploadOss(this.oss.host, formData)
      
      if (res.statusCode === 200) {
        const imagePath = this.oss.host + '/' + key
        this.remark_pic = imagePath
      } else {
        this.$message.warn('上传失败，请稍后重试')
      }
    },
    // 显示审核状态文字
    getStatusText (status) {
      let statusText = ''
      for (let key in WITHDRAW_STATUS) {
        if (status === WITHDRAW_STATUS[key].VALUE) {
          statusText = WITHDRAW_STATUS[key].TEXT
        }
      }
      return statusText
    },
    // 判断奇怪的需求‘请选择’
    processStatusValidator (rule, value, callback) {
      if (value === '请选择') {
        callback('请选择审核结果')
      }
      callback()
    }
  }
}
</script>

<style lang='less' scoped>
@import '../../assets/less/var.less';
#withdraw-approval-detail {
  @blueColor: #0B24FB;
  .look-over-withdraw-record {
    // color: @red-color;
    color: @blueColor;
    cursor: pointer;
  }
  .approval-submit-btn {
    margin-left: 8px;
  }
  .upload-pic-btn {
    color: @blueColor;
  }
}
</style>